import axios from 'axios'
import store from '@/store'
import router from '@/router'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_AX_BASE
})

let refreshTimer = null

const persistAuthData = (tokenIssueTime) => {
  if (tokenIssueTime) {
    localStorage.setItem('authTokenIssueTime', tokenIssueTime.toString())
  }
}

const startRefreshTimer = (expiresIn) => {
  const bufferTime = 0.8
  const refreshTime = (expiresIn * bufferTime) * 1000
  if (refreshTimer) clearTimeout(refreshTimer)

  refreshTimer = setTimeout(async () => {
    try {
      await refreshToken()
    } catch (error) {
      console.error('Error al renovar el token automáticamente:', error)
      store.commit('auth/DELETE_AUTH')
      router.push('/auth/signin').catch(() => {})
    }
  }, refreshTime)
}

const refreshToken = async () => {
  try {
    const { data } = await store.dispatch('auth/REFRESH_TOKEN')
    store.dispatch('auth/GET_USER')
    const tokenIssueTime = Math.floor(Date.now() / 1000)
    persistAuthData(tokenIssueTime)

    if (data && data.expires_in) {
      startRefreshTimer(data.expires_in)
    } else {
      console.error('No se recibió expires_in después del refresh')
      store.commit('auth/DELETE_AUTH')
      router.push('/auth/signin').catch(() => {})
    }
  } catch (error) {
    console.error('Error al intentar renovar el token:', error)
    throw error
  }
}

const initializeRefreshTimer = () => {
  const {
    token,
    expires_in: expiresIn,
    refresh_expires_in: refreshExpiresIn
  } = store.state.auth

  const tokenIssueTime =
    store.state.auth.tokenIssueTime ||
    Number(localStorage.getItem('authTokenIssueTime'))

  if (!token || !expiresIn || !refreshExpiresIn || !tokenIssueTime) {
    console.warn('Faltan datos necesarios para inicializar el temporizador.')
    return
  }

  const currentTime = Math.floor(Date.now() / 1000)
  const remainingTokenTime = expiresIn - (currentTime - tokenIssueTime)
  const remainingRefreshTime = refreshExpiresIn - (currentTime - tokenIssueTime)

  if (remainingRefreshTime <= 0) {
    console.warn('El refresh token ha expirado.')
    store.commit('auth/DELETE_AUTH')
    router.push('/auth/signin').catch(() => {})
    return
  }

  if (remainingTokenTime > 0) {
    startRefreshTimer(remainingTokenTime)
  } else {
    console.warn('El token de acceso ya expiró. Intentando renovar ahora...')
    refreshToken()
  }
}

http.interceptors.request.use(
  (config) => {
    if (
      store?.state?.auth?.token &&
      !config.url.endsWith('/organizations') &&
      !config.url.endsWith('/token')
    ) {
      config.headers.Authorization = `Bearer ${store?.state?.auth?.token}`
    }
    if (
      store?.state?.auth?.account?.acc_id &&
      !config.url.endsWith('/password-change') &&
      !config.url.endsWith('/workspaces') &&
      !config.url.endsWith('/organizations') &&
      !config.url.endsWith('/token') &&
      !config.url.endsWith('/userinfo')
    ) {
      config.headers['x-ax-workspace'] = store?.state?.auth?.account?.acc_id
    }
    return config
  },
  (error) => Promise.reject(error)
)

http.interceptors.response.use(
  (response) => {
    if (response.config.url.endsWith('/token') && response.status === 200) {
      const tokenIssueTime = Math.floor(Date.now() / 1000)
      persistAuthData(tokenIssueTime)
      startRefreshTimer(response.data.expires_in)
    }
    return response
  },
  (error) => {
    if (error.response?.status === 401) {
      refreshToken()
    }
    return Promise.reject(error)
  }
)

document.addEventListener('DOMContentLoaded', () => {
  if (store?.state?.auth?.token) {
    initializeRefreshTimer()
  }
})

export default http
