import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './sass/main.sass'
import './sass/variables.scss'
import './sass/variables-css.scss'
import VueI18n from 'vue-i18n'
import Vuelidate from 'vuelidate'
import LoadScript from 'vue-plugin-load-script'
import VueClipboard from 'vue-clipboard2'
import VuetifyDialog from 'vuetify-dialog'
import VConfirm from './components/VDialog/VConfirm'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import helpers from './utils/helpers'

Vue.config.productionTip = false

Vue.use(VueTelInput)

const authChannel = new BroadcastChannel('auth')
authChannel.onmessage = (event) => {
  if (event.data === 'logout') {
    store.commit('auth/DELETE_AUTH')
  }
}
// vuetify dialog
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.prototype.$dialog.component('warning', VConfirm, {
  waitForResult: true,
  'overlay-color': 'overlay',
  actions: {
    false: 'Cancelar',
    true: {
      text: 'Continuar',
      color: 'blue-500'
    }
  }
})
Vue.prototype.$dialog.message = {
  info: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'blue-grey darken-1',
    position: 'top-center',
    ...options
  }),
  error: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'red darken-1',
    position: 'top-center',
    rounded: '10px',
    ...options
  }),
  success: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'green darken-1',
    position: 'top-center',
    ...options
  }),
  warning: (message, options) => Vue.prototype.$dialog.toast({
    text: message,
    color: 'amber darken-1',
    position: 'top-center',
    ...options
  })
}
// end vuetify dialog

Vue.use(helpers)
Vue.use(VueI18n)
Vue.use(Vuelidate)
Vue.use(LoadScript)
Vue.use(VueClipboard)

Vue.prototype.$CLIENT_BASE = process.env.VUE_APP_CLIENT_BASE

const i18n = new VueI18n({
  locale: 'es',
  fallbackLocale: 'en',
  messages: {},
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js', { scope: './' }).then((registration) => {
  }).catch((error) => {
    console.error('Error al registrar el Service Worker:', error)
  })
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
