import store from '@/store'

const helpers = {
  hasSomePermission (list = []) {
    if (!store.state.auth?.userAccount['com.axteroid.is_staff']) {
      return list?.some((rp) => store.state.auth?.userAccount?.resource_access?.webapp?.roles?.some((p) => rp.includes(p)))
    }
    return true
  },
  hasPermission (list = []) {
    if (!store.state.auth?.userAccount['com.axteroid.is_staff']) {
      return list?.every((l) => store.state.auth?.userAccount?.resource_access?.webapp?.roles?.some((v) => v === l))
    }
    return true
  }
}

export default {
  install (Vue) {
    Vue.prototype.$helpers = helpers
  }
}
