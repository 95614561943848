export default [
  {
    title: 'Inicio',
    icon: 'home',
    link: 'AccountDashboard',
    options: [],
    use: ['CL', 'PE']
  },
  {
    title: "Métricas",
    icon: "metrics",
    link: "MetricsList",
    use: ['CL', 'PE'],
    options: [
      {
        title: "Resumen emisión",
        link: "MetricsList",
        associatedRoutes: [],
        use: ['CL', 'PE']
      },
      {
        title: "Resumen recepción",
        link: "RecMetricsList",
        associatedRoutes: [''],
        use: ['CL', 'PE']
      }
    ]
  },
  {
    title: "Emisión",
    icon: "document",
    link: "DocumentsList",
    use: ['CL', 'PE'],
    options: [
      {
        title: "Documentos",
        link: "DocumentsList",
        associatedRoutes: ["DocumentsCreate", "DocumentsRetrieve"],
        use: ['CL', 'PE']
      },
      {
        title: "Resúmenes",
        link: "SummariesList",
        associatedRoutes: ["SummariesRetrieve"],
        use: ['PE']
      },
      {
        title: "Emisión por lote",
        link: "DocumentsBatchListCreate",
        associatedRoutes: ["DocumentsBatchRetrieve"],
        use: ['CL', 'PE']
      },
      {
        title: "Cuadratura",
        link: "ReconciliationsListCreate",
        associatedRoutes: ["ReconciliationsRetrieve"],
        use: ['CL', 'PE']
      },
      {
        title: 'Clientes',
        link: 'CustomerListCreate',
        associatedRoutes: ['CustomerRetrieveUpdate'],
        use: ['CL', 'PE']
      },
      {
        title: 'SIRE',
        link: 'SireList',
        associatedRoutes: [''],
        use: ['PE']
      }
    ]
  },
  {
    title: "Recepción",
    icon: "purchase",
    link: "PurchaseList",
    options: [],
    associatedRoutes: ["PurchaseRetrieve"],
    use: ['CL', 'PE']
  },
  {
    title: "Tiendas",
    icon: "pos",
    options: [],
    link: "BranchListCreate",
    use: ['CL', 'PE']
  },
  {
    title: "Centro de ayuda",
    description: "",
    link: "",
    options: [],
    use: ['CL', 'PE']
  }
]
