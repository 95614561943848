<template>
  <v-row no-gutters>
    <v-navigation-drawer
      :mini-variant="mini"
      permanent
      app
      stateless
      fixed
      hide-overlays
      :width="215"
    >
    <v-list class="px-0 py-5">
      <v-list-item class="px-4 py-0">
        <img class="ml-n1" :src="require(`@/assets/appAsset-brand--axteroid-icon${$route.params.prefix ? `-test${$vuetify.theme.dark ? '-dark' : ''}` : ''}.svg`)" />
        <v-spacer />
      </v-list-item>
      <v-divider class="mt-5 mx-3" />
      <v-list-item class="px-4 py-1">
        <v-btn class="ml-n4" small text @click.stop="mini=!mini" :ripple="false">
          <img class="" :src="require(`@/assets/navigationdrawer-ax/${mini ? 'open' : 'close'}-icon${$vuetify.theme.dark ? '--dark' : ''}.svg`)" :height="17" />
        </v-btn>
      </v-list-item>
      <template v-if="!!$store.state.auth.account.acc_id">
        <v-divider class="mt-0 mx-3" />
        <v-list-item v-for="link in list.slice(0, list.length - 1)" class="px-3 py-1" active-class="transparent" exact :key="link.title" link :ripple="false">
          <v-list-group v-if="link?.options?.length" class="ma-0" :value="!mini && selectedIcon === link.link ? link.show : false" active-class="transparent blue-500--text" :no-action="!!mini" exact :ripple="false" :key="mini">
            <template v-slot:activator>
              <v-list-item-icon class="mx-0" style="width: 30px;">
                <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="20" offset-x :key="mini">
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot="{ hover }">
                      <v-row align="center" no-gutters>
                        <v-btn text class="pa-0" v-bind="attrs" v-on="mini ? on : false" style="z-index: 100 !important; position: absolute;">
                          <img class="ml-1 icon-panel" :src="require(`@/assets/navigationdrawer-ax/${link.icon}-icon${selectedIcon === link.link || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" />
                          <v-icon class="ml-1 btn-tooltip-panel" :color="hover ? 'blue-500' : `${selectedIcon === link.link ? 'blue-500' : 'grey-700'}`" v-if="mini" size="16">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </v-row>
                    </v-hover>
                  </template>
                  <v-list class="pa-0">
                    <span class="px-3 mb-2 d-block grey-700--text subtitle-2 font-weight-medium">{{link.title}}</span>
                    <v-list-item class="px-3 mb-n1" active-class="transparent" :ripple="false" v-for="(option) in link.options" :key="option.link" :to="{ name: option.link, params: currentParams }" exact>
                      <v-list-item-title class="body-2" :class="$route.name === option.link ? 'blue-500--text' : 'text--grey-700'" >{{ option.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-icon>
              <v-list-item-title class="d-inline-block subtitle-2 text-truncate pl-4" :class="selectedIcon === link.link ? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'" style="width: 118px; line-height: 1.6;">
                <template>{{link.title}}</template>
              </v-list-item-title>
            </template>
            <v-list-item class="pl-3 py-0" :class="$route.name === option.link || option.associatedRoutes.includes($route.name) ? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'" active-class="transparent" :ripple="false" v-for="(option) in link.options" :key="option.link" :to="{ name: option.link, params: currentParams }" exact>
              <v-divider vertical />
              <v-list-item-title class="pl-2 py-1" :class="$route.name === option.link || option.associatedRoutes.includes($route.name) ? 'blue-500--text' : 'grey-500--text'">{{option.title}}</v-list-item-title>
            </v-list-item>
          </v-list-group>
          <template v-else>
            <v-list-item class="d-flex align-center" :to="{ name: link.link, params: currentParams }" :ripple="false" exact active-class="transparent font-weight-black">
              <v-list-item-icon class="mr-4 my-1">
                <v-hover v-slot="{ hover }">
                  <img class="ml-1" :src="require(`@/assets/navigationdrawer-ax/${link.icon}-icon${selectedIcon === link.link || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" />
                </v-hover>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-2" :class="selectedIcon === link.link ? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'" style="line-height: 1.5;">{{link.title}}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item>
      </template>
    </v-list>

      <!-- settings -->
      <template v-slot:append>
        <v-divider class="mx-2" />
        <v-list dense class="px-4">
          <!-- <v-list-item href="https://docs.axteroid.com/" target="_blank" class="" :ripple="false" active-class="transparent">
            <v-list-item-icon>
              <v-hover v-slot="{ hover }">
                <span class="d-flex">
                  <img :src="require(`@/assets/navigationdrawer-ax/help-icon${hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="18" />
                </span>
              </v-hover>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5">
              <template>
                <span class="mt-n1 d-inline-block grey-700--text subtitle-2 font-weight-medium text-truncate">Centro de Ayuda</span>
              </template>
            </v-list-item-title>
          </v-list-item> -->

          <!-- <v-list-item v-if="!!$store.state.auth.account.acc_id" :to="{ name: 'DownloadCenterList', params: currentParams }"  :ripple="false" active-class="transparent" class="mt-n7">
            <v-list-item-icon>
              <v-hover v-slot="{ hover }">
                <span class="d-flex">
                  <img :src="require(`@/assets/navigationdrawer-ax/download-icon${selectedIcon === 'DownloadCenterList' || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="16" style="margin-left: 3px" />
                </span>
              </v-hover>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5 subtitle-2" :class="selectedIcon === 'DownloadCenterList'? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'">Centro de descargas</v-list-item-title>
          </v-list-item> -->

          <v-list-item @click="saveDarkMode()" :ripple="false" active-class="transparent">
            <v-list-item-icon>
              <v-hover v-slot="{ hover }">
                <span class="d-flex">
                  <img :src="require(`@/assets/navigationdrawer-ax/dark-mode-icon${hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="16" style="margin-left: 3px" />
                </span>
              </v-hover>
            </v-list-item-icon>
            <v-list-item-title class="ml-n5 mt-n2 subtitle-2 grey-700--text font-weight-medium">Modo {{ !$vuetify.theme.dark ? 'oscuro' : 'claro' }}</v-list-item-title>
          </v-list-item>
        </v-list>

        <v-divider class="mx-2 mt-n1" />
        <v-list dense class="px-4 pt-0 mb-2">
          <v-list-item :ripple="false" active-class="transparent" class="mt-1">
            <v-list-group :value="!mini && selectedIcon === 'AuthSettings' ? true : false" active-class="transparent blue-500--text" exact :ripple="false" :key="mini" :no-action="!!mini">
              <template v-slot:activator>
                <v-list-item-icon class="mr-0" style="width: 30px;">
                  <v-menu class="list-active-draw" right :close-delay="100" :open-on-hover="true" :open-delay="10" :nudge-right="20" offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-row align="center" no-gutters>
                          <img class="ml-1 icon-panel" :src="require(`@/assets/navigationdrawer-ax/account-icon${selectedIcon === 'AuthSettings' || hover ? '-active' : $vuetify.theme.dark ? '-dark' : ''}.svg`)" :height="16" />
                          <v-btn text class="ml-5 pa-0" v-bind="attrs" v-on="on" style="z-index: 100 !important; position: absolute; ">
                            <v-icon class="ml-1 btn-tooltip-panel" :color="hover ? 'blue-500' : `${selectedIcon === 'AuthSettings' ? 'blue-500' : 'grey-700'}`" v-if="mini" size="16">mdi-dots-vertical</v-icon>
                          </v-btn>
                        </v-row>
                      </v-hover>
                    </template>
                    <v-list class="pa-0">
                      <span class="px-3 mb-2 d-block grey-700--text subtitle-2">Configuración de la cuenta</span>
                      <v-list-item class="px-3 mb-n1" active-class="transparent" :class="selectedIcon === 'AuthSettings' ? 'blue-500--text ' : 'text--grey-700'" :to="{ name: 'AuthSettings', params: currentParams }" :ripple="false" exact @click.stop="selectedIcon = 'AuthSettings'">
                        <v-list-item-title class="body-2 font-weight-medium">Configuración de la cuenta</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="px-3" @click="deleteAuth" active-class="transparent font-weight-black" :ripple="false" exact>
                        <v-list-item-title class="body-2 font-weight-medium text--grey-700">Cerrar sesión</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-icon>
                <v-list-item-title class="pl-3 mr-n4" style="line-height: 3.5;">
                  <template>
                    <span class="d-inline-block subtitle-2 text-truncate" :class="selectedIcon === 'AuthSettings' ? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'" style="max-width: 120px;">{{ $store.state.auth.userAccount.given_name || 'Sin definir' }} {{$store.state.auth.userAccount.family_name}}</span>
                    <span class="mt-n4 d-block body-2 text-truncate" :class="selectedIcon === 'AuthSettings' ? 'blue-500--text ' : 'grey-700--text'" style="max-width: 120px;">{{ $store.state.auth.userAccount.email || 'Sin definir' }}</span>
                  </template>
                </v-list-item-title>
              </template>
              <v-list-item class="py-0 pl-2 mt-4" active-class="transparent font-weight-black" :class="selectedIcon === 'AuthSettings' ? 'blue-500--text font-weight-semibold' : 'grey-700--text font-weight-medium'" :to="{ name: 'AuthSettings', params: currentParams }" :ripple="false" exact>
                <v-divider vertical />
                <v-list-item-title class="body-1 font-weight-medium grey-500--text pl-3 pt-1`">Configuración de la cuenta</v-list-item-title>
              </v-list-item>
              <v-list-item class="py-0 pl-2 grey-700--text" @click="deleteAuth" active-class="transparent font-weight-black" :ripple="false" exact>
                <v-divider vertical />
                <v-list-item-title class="body-1 font-weight-medium grey-500--text pl-3 pt-2">Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </v-row>
</template>
<script>
import itemList from '@/collections/itemsNavigationDrawer'

export default {
  data: () => ({
    drawer: true,
    menu: false,
    mini: true,
    list: [],
    selectedIcon: 'Dashboard'
  }),
  watch: {
    mini (val) {
      this.$store.commit('base/EXPAND_NAVIGATION_DRAWER', !val)
    },
    $route: {
      immediate: false,
      handler: function (val) {
        this.selectedIcon = val.meta.parent
      }
    },
    '$store.state.auth.account.country': {
      handler () {
        this.setDrawerItems()
      },
      inmediate: true,
      deep: true
    }
  },
  computed: {
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  },
  mounted () {
    this.selectedIcon = this.$route.meta.parent
    this.$nextTick(() => {
      this.list = []
      this.list = JSON.parse(JSON.stringify(itemList))
      this.setDrawerItems()
    })
  },
  methods: {
    deleteAuth () {
      const authChannel = new BroadcastChannel('auth')
      authChannel.postMessage('logout')
      authChannel.close()
      this.$store.dispatch('auth/DELETE_AUTH')
    },
    saveDarkMode () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('is-active-dark-mode-ax', this.$vuetify.theme.dark)
    },
    setDrawerItems () {
      this.list = this.list.filter(item => item.use.includes(this.$store.state.auth.account.country))
      this.list.forEach((item) => {
        item.options = item.options.filter(({ use }) => use.includes(this.$store.state.auth.account.country))
      })
    }
  }
}
</script>