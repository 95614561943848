<template>
  <div v-scroll="onScroll">
    <v-app-bar :class="['mx-2', { 'mt-1': $route.params.prefix && this.offsetTop === 0}]" flat fixed app absolute v-if="$store.state.auth.account.acc_id && $route.meta.displayOrganization" style="position: fixed !important; transition: none !important; z-index:6;">
      <!-- alert test mode -->
      <v-alert :class="`${$route.params.prefix ? 'inf-alert-purple fontTest--text' : 'inf-alert-grey fontDraw--text'} ml-4 my-3 mr-3`" :height="36" width="100%" text outlined tile>
        <div class="d-flex align-center ml-n1 mr-n1">
          <v-icon :color="`${$route.params.prefix ? 'blue-dark' : 'fontDraw-mode'}`" size="18">mdi-information</v-icon>
          <div class="ml-3 description-alert-mode">
            <span :class="`subtitle-2 font-weight-semibold ${$route.params.prefix ? 'blue-dark' : 'fontDraw-mode'}--text`">Estas navegando en un modo {{ $route.params.prefix ? 'prueba' : 'producción' }}</span>
          </div>
          <v-spacer />
          <v-switch
            v-model="isTestMode"
            class="mr-n1 ml-3 switch-modeTest"
            :color="`${$route.params.prefix ? 'blue-dark' : ''}`"
            hide-details
            :ripple="false"
            flat
            :readonly="isReadonly"
            persistent-hint
            @click="test"
          />
        </div>
      </v-alert>
      <!-- end test mode -->
      <v-spacer />
      <!-- account menu -->
      <v-menu v-if="$store.state.auth.account.acc_id" close-on-click close-on-content-click offset-y width="300" :nudge-bottom="8"  left transition="slide-y-transition">
        <template v-slot:activator="{on, attrs}">
          <v-btn class="mr-4" v-on="on" v-bind="attrs" outlined :ripple="false">
            <img :src="require(`@/assets/account/account-choose-icon${ $vuetify.theme.dark ? '-dark' : ''}.svg`)" :width="16" />
            <span class="mx-2 text-truncate" style="max-width: 150px;">{{$store.state.auth.account.name | capitalize}}</span>
            <v-icon class="mr-n1" size="18">mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item class="px-3" active-class="transparent" :to="{ name: 'AccountBasic', params: currentParams}" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="grey-700" left small>mdi-cog</v-icon>Configuración</v-list-item-title>
          </v-list-item>
          <v-list-item class="px-3" @click="goTo" :ripple="false">
            <v-list-item-title class="body-2"><v-icon color="grey-700" left small>mdi-swap-horizontal</v-icon>Cambiar organización</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- end account menu -->
    </v-app-bar>
    <!-- dialog confirm mode -->
    <v-dialog v-model="dialogMode" width="730" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Modo {{$route.params.prefix ? 'producción' : 'prueba' }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogMode=false, confirm = ''" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-mode.svg`)" :width="180" />
            </v-col>
            <v-col class="pr-4 pl-12">
              <span class="d-block body-1 pt-2">
                Al confirmar, estarás cambiando de modo <span class="font-weight-semibold"> {{ $route.params.prefix ? 'prueba' : 'producción' }}</span> a modo <span class="font-weight-semibold">{{ !$route.params.prefix ? 'prueba' : 'producción' }}</span>. Por lo tanto, los documentos que generes en este modo se enviarán a {{ $t('generals.SUNAT') }}. Recuerda que podrás volver al modo actual en cualquier momento.
              </span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para ir al
                <span class="font-weight-semibold"> MODO {{ !$route.params.prefix ? 'PRUEBA' : 'PRODUCCIÓN' }}.</span>
              </span>
              <v-text-field v-model="confirm" @keyup.enter="testMode(true)" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogMode=false, isTestMode = $route.params.prefix, confirm = ''" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="testMode(true)" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm mode -->
  </div>
</template>
<script>
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  mixins: [
    GenericViewMixin
  ],
  components: {
  },
  data: () => ({
    isReadonly: true,
    isTestMode: false,
    dialogMode: false,
    confirm: '',
    offsetTop: 0
  }),
  computed: {
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler () {
        this.isTestMode = this.$route.path.startsWith('/test')
      }
    }
  },
  mounted () {
    this.isTestMode = !!this.$route.params.prefix
  },
  methods: {
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    test () {
      this.dialogMode = !this.dialogMode
    },
    deleteAuth () {
      this.$store.dispatch('auth/DELETE_AUTH')
    },
    testMode (e) {
      if (e && !this.$route.path.startsWith('/test')) {
        this.$router.replace({ path: `/test${this.$route.path}`, query: this.$route.query }).catch(err => err)
      } else {
        let currentParams = JSON.parse(JSON.stringify(this.$route.params))
        if (this.$route.params.prefix) delete currentParams.prefix
        this.$router.replace({name: `${this.$route.name}`, params: currentParams, query: this.$route.query}).catch(err => err)
      }

      this.isTestMode = !this.isTestMode
      this.dialogMode = false
      this.confirm = ''
    },
    async goTo () {
      await this.$store.commit('base/EXPAND_NAVIGATION_DRAWER', false)
      this.$router.push({ name: 'AuthChoose' })
    }
  }
}
</script>