import axios from 'axios'
import http from './http'

const setFormatParams = (params = null) => {
  let aux = null
  let formatParams = null
  let formatParamsTypeList = ''
  let cont = 0
  Object.entries(params).forEach(([key, value]) => {
    if (!value || typeof value === 'undefined') return
    if (typeof value === 'object') {
      cont += 1
      let result = value.map((v) => `${key}=${v}`).join('&')
      formatParamsTypeList += cont > 1 ? `&${result}` : result
    } else {
      aux = { ...aux, [key]: value }
      formatParams = Object.entries(aux).map(([k, v]) => `${k}=${v}`).join('&')
    }
  })
  return { formatParams, formatParamsTypeList }
}

const deleteQuery = (query = {}) => {
  if (query?.ref) delete query.ref
}

const auth = {
  signUp: (user, invitation = null) => {
    return new Promise((resolve, reject) => {
      const params = {}
      if (invitation) {
        params.invitation = invitation
      }
      http.post('/auth/registration/', user, { params: params })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getAuth: (resource, payload) => {
    return new Promise((resolve, reject) => {
      http.post(`/login/realms/axt/protocol/openid-connect${resource}`, payload, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getRefreshToken: (token) => {
    return new Promise((resolve, reject) => {
      http.post('/login/realms/axt/protocol/openid-connect/token', { grant_type: 'refresh_token', refresh_token: token, client_id: 'webapp' }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  tokenVerify: (token) => {
    return new Promise((resolve, reject) => {
      http.post('/token/verify/', { token: token })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  bulkInvitation: (persons) => {
    return new Promise((resolve, reject) => {
      http.post('/invitations/send_bulk/', { persons: persons, url: process.env.VUE_APP_API_BASE.replace(/api/g, 'ax') })
      .then(() => {
        resolve()
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  acceptInvitation: (key, payload) => {
    return new Promise((resolve, reject) => {
      let currentPayload = {...payload}
      delete currentPayload.emal
      http.post(`${key}/accept-invite/`, { password1: currentPayload.password1, password2: currentPayload.password2 })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  passwordReset: (email) => {
    return new Promise((resolve, reject) => {
      http.post('/password/reset/', { email: email })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  passwordResetConfirm: (payload) => {
    return new Promise((resolve, reject) => {
      http.post('/auth/password/reset/confirm/', {
        uid: payload.uid,
        token: payload.token,
        new_password1: payload.new_password1,
        new_password2: payload.new_password2
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  getUser: () => {
    return new Promise((resolve, reject) => {
      http.get('login/realms/axt/protocol/openid-connect/userinfo')
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  patchUser: (payload) => {
    return new Promise((resolve, reject) => {
      http.patch('/auth/user/', {
        first_name: payload.first_name,
        last_name: payload.last_name
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  postUserPassword: (payload) => {
    return new Promise((resolve, reject) => {
      http.post('login/realms/axt/custom-resources/password-change', {
        new_password1: payload.new_password1,
        new_password2: payload.new_password2,
        old_password: payload.old_password
      })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  retrieveTrace: () => {
    return new Promise((resolve, reject) => {
      axios.get(process.env.VUE_APP_API_TRACE_URL)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  }
}

const general = {
  getList: (resource, query = {}) => {
    return new Promise((resolve, reject) => {
      http.get(`/${resource}/`, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  getDetail: (resource, id) => {
    return new Promise((resolve, reject) => {
      http.get(`/${resource}/${id}/`)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  postObject: (resource, payload) => {
    return new Promise((resolve, reject) => {
      http.post(`/${resource}/`, payload)
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}
const account = {
  list: (resource, query = {}, responseType = 'json') => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      let request = `/${resource}`
      http.get(request, {
        params: query,
        paramsSerializer: (params) => {
          const { formatParams, formatParamsTypeList } = setFormatParams(params)
          return formatParams && formatParamsTypeList ? `${formatParams}&${formatParamsTypeList}` : `${!formatParams ? `${formatParamsTypeList}` : `${formatParams}`}`
        },
        responseType: responseType
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  retrieve: (resource, id, detail, query = {}, headers = {}, responseType = '') => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      let request = id ? `/${resource}/${id}` : `/${resource}`
      if (detail) {
        request += `${detail}/`
      }
      http.get(request, { params: query, responseType: responseType, headers: headers })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  get: (resource, query = {}) => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      http.get(`/${resource}`, { params: query })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  create: (resource, payload, detail = null, id = null, headers = {}, query = {}, isNotTest = false) => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      const q = (resource.search(/with-caf/) !== -1 || resource.search(/upload/) !== -1 || resource.search(/configuration/) !== -1) || isNotTest ? {} : query
      let request = `/${resource}`
      if (detail && id) {
        request += `${id}/${detail}/`
      }
      http.post(request, payload, { params: q, headers: headers })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  },
  update: (resource, id, payload, headers = {}, detail = '') => {
    return new Promise((resolve, reject) => {
      let request = resource
      if (detail && id) {
        request += `/${id}/${detail}`
      }
      if (detail) {
        request += `/${detail}`
      }
      if (id) {
        request += `/${id}/`
      }
      http.patch(request, payload, headers)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  destroy: (resource, id, query = {}, detail = '') => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      let request = resource
      if (detail && id) {
        request += `/${id}/${detail}`
      }
      if (id) {
        request += `/${id}/`
      }
      http.delete(request, { params: query })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
    })
  },
  download: (resource, payload, detail = null, id = null, headers = {}, query = {}) => {
    deleteQuery(query)
    return new Promise((resolve, reject) => {
      let request = `/${resource}`
      if (detail && id) {
        request += `${id}/${detail}/`
      }
      http.post(request, payload, { params: query, responseType: 'blob', headers: headers })
      .then((response) => {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'documentos.zip'
        link.click()

        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
    })
  }
}

export { auth, general, account }